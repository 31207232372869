$dark: #333;
$light-text: #ccc;
$dark-text: #333;

.meeting {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  font-size: 16px;
  background-color: $dark;
  overflow-y: auto;
  z-index: 1021;
  display: flex;
  color: $light-text;
  flex-direction: column;

  .presentation {
    width: 100%;
    height: calc(100vh - 150px);
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      height: 100%;
      width: auto;
    }
  }
  .participants {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    & > * {
      margin-bottom: 12px;
      margin-right: 12px;
    }
  }
    
}

.connecting {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #eee;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 64px;
  z-index: 5001;
  cursor: default;
  user-select: none;
}

.form-control {
  font-size: 12px !important;
}
