$width: 320px;
$height: 240px;
$border: 8px;
$background: #d8d8d8;

box4x3 {
  width: $width + $border * 2;
  height: $height + $border * 2;
  box-sizing: content-box;
}

box16x9 {
  width: $width + $border * 2;
  height: 180px + $border * 2;
  box-sizing: content-box;
}

.subscription {
  background-color: $background;
  border: $border solid $background;

  @extend box4x3;
  video {
    @extend box4x3;
    background-color: $background;
    background-position: center center;
    background-size: contain;
  }
  .hidden {
    display: none;
  }
  .overlay {
    @extend box4x3;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    position: absolute;
  }
}

.camera-subscription {
  background-color: #d8d8d8;
  video {
    background: url('../assets/default-poster.png');
    background-position: center center;
    background-size: contain;
  }
  &.silent {
    border-color: transparent;
  }
  &.speaking {
    border-color: orange;
  }

  .overlay {
    .unmute {
      left: 50% !important;
      top: 50% !important;
      margin-left: -90px !important;
      margin-top: -90px !important;
      width: 180px !important;
      height: 180px !important;
      background-color: rgba(255, 255, 255, 0.5);
      border-radius: 24px;
      color: #333;
      position: absolute;
      z-index: 1001;
      border: none;
      font-size: 16px;
    }
  }
  .controls-panel {
    @extend box4x3;
    z-index: 2;
    position: absolute;
    justify-content: space-between;
    flex-direction: column;
    display: flex;

    .name-display {
      background-color: rgba(255, 255, 255, 0.8);
      color: #000;
      padding: 6px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      opacity: 0.3;
    }
    .volume-control {
      margin: 12px 64px;
      display: none;
    }
  }
  .controls-panel:hover {
    .volume-control {
      display: block;
    }
    .name-display {
      opacity: 1;
    }
  }
}

.screen-subscription {
  @extend box16x9;
  video {
    @extend box16x9;
    background: url('../assets/desktop16x9.png');
  }
}

.camera-control-subscription {
  video {
    background: url('../assets/default-poster.png');
    background-position: center center;
    background-size: contain;
  }
  .controls-panel {
    @extend box4x3;
    z-index: 2;
    position: absolute;
    background-color: transparent;
    justify-content: space-between;
    flex-direction: column;
    display: flex;
    .buttons {
      background-color: rgba(255, 255, 255, 0.5);
      padding: 6px;
      width: 100%;
      text-align: center;
      display: none;
      button {
        width: 48px;
        background-color: transparent;
        border: none;
        outline: none;
      }
    }
    .icon {
      width: 24px;
      height: 24px;
    }
    .name-display {
      background-color: rgba(255, 255, 255, 0.8);
      color: #000;
      padding: 6px;
      width: 100%;
      text-align: center;
      font-size: 16px;
      opacity: 0.3;
    }
  }
  .controls-panel:hover {
    .buttons {
      display: block;
    }
    .name-display {
      opacity: 1;
    }
  }
}
